/* eslint-disable no-constant-condition */
import { Box, Flex, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { IS_JEWLR } from "helpers/application"

import Breadcrumb from "./breadcrumb"
import Description from "./description"
import Title from "./title"

const Container = styled(Box).attrs((props) => ({
  mb: "14px",
  mt: 2,
  pt: { desktop: 2 },
  ...props,
}))``

const ProductCount = styled(Typography).attrs({
  color: "#757575",
})`
  font-size: 14px;
`

const InternalHeading = ({ totalProducts }) => {
  return (
    <Container>
      <Flex
        alignItems="flex-start"
        justifyContent={IS_JEWLR() ? "space-between" : "flex-end"}
      >
        {IS_JEWLR() && <Breadcrumb />}
        <div aria-atomic aria-live="polite">
          <ProductCount>{totalProducts} Products</ProductCount>
        </div>
      </Flex>
      <Flex alignItems="flex-start" justifyContent="space-between" mt={2}>
        <Box width={{ desktop: "75%" }}>
          <Title />
          <Description />
        </Box>
      </Flex>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    totalProducts: state.collection.pagination.total_products_count,
  }
}

const mapDispatchToProps = () => {
  return {}
}

InternalHeading.propTypes = {
  totalProducts: PropTypes.number,
}

const Heading = connect(mapStateToProps, mapDispatchToProps)(InternalHeading)

export default Heading
