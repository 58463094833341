import { Box, Divider, Flex, Image, Shimmer } from "@jewlr/storybook/core"
import React from "react"
import styled, { keyframes } from "styled-components"

import { GridContainer } from "areas/collection2/products"
import ItemContainer from "areas/collection2/products/item-container"

const shimmer = keyframes`
  from {
    background-position: -256px 0;
  }
  to {
    background-position: 256px 0;
  }
`

const StyledImage = styled(Image)`
  animation: ${shimmer} 1.5s forwards infinite;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: auto;
  max-width: 100%;
  overflow: hidden;
  ${({ theme }) => theme.mediaQueries.tablet`
    width: 100%;
    max-width: 256px;
  `}
  ${({ theme }) => theme.mediaQueries.widescreen`
    max-width: 300px;
  `}
`

const ShimmersPortal = () => (
  <Box containWide px={2}>
    <Flex justifyContent="end">
      <Shimmer height="12px" mt={2} width={{ _: "40px", desktop: "100px" }} />
    </Flex>
    <Box mx={1} my={2} py={2}>
      <Shimmer height="17px" mb={2} width="150px" />
      <Flex justifyContent="space-between">
        <Box width={{ desktop: "75%" }}>
          <Shimmer
            height={{ _: "12px", desktop: "20px" }}
            width={{ _: "300px", desktop: "600px" }}
          />
        </Box>
      </Flex>
    </Box>
    <Divider bg="grey.10" display={{ _: "none", tablet: "block" }} />

    <Flex justifyContent="center">
      <Box flex="1">
        <GridContainer>
          {[...Array(12)].map((_, i) => (
            <ItemContainer key={`shimmerplacholder${i}`}>
              <StyledImage
                alt="placeholder ring"
                ratio="auto 1 / 1"
                src={require(`images/collections/placeholder_masks/ring_${
                  (i % 6) + 1
                }.svg`)}
              />
            </ItemContainer>
          ))}
        </GridContainer>
      </Box>
    </Flex>
  </Box>
)

export default ShimmersPortal
